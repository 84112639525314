.image {
    width: 100%;
    aspect-ratio: 16/9;
    height: auto;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.aspect-default {
    aspect-ratio: 16/9;
}

.aspect-43 {
    aspect-ratio: 4/3;
}

.aspect-square {
    aspect-ratio: 1/1;
}

.Image-link {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
