.profilebasicinfo-basic {
    padding: 5%;
    background: var(--grey);
    color: black;
}

.profilebasicinfo-basic a + a {
    margin-left: 1ch;
}

@media (min-width: 800px) {

    .profilebasicinfo-basic {
        padding: 3ch;
    }
}