.topbanner {
    margin: 0;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    aspect-ratio: 16/9;
    padding-bottom: 2rem;
}

.topbanner-bodycontainer {
    width: 80%;
    margin-inline: auto;
    color: white;
    align-self: flex-end;
}

.topbanner-title {
    margin: 0px;
    color: #FFFFFF;
    font-size: 1.25em;
    font-weight: 600;
}

.topbanner-line {
    display: block;
    width: 100%;
    height: .08em;
    background-color: currentColor;
    -ms-transform-origin: top left;
    transform-origin: top left;
  }

.topbanner-content {
    position: relative;
    top: 10px;
    font-size: 2em;
    color: #FFFFFF;
    font-weight: 300;
}

@media (min-width: 752px) {

    .topbanner {
        aspect-ratio: 16/6;
        max-height: 70vh;
    }

    .topbanner-title {
        font-size: 1.5em;
    }

    .topbanner-content {
      font-size: 2.5em;
    }

}